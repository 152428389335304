<template>
  <SharedKitButton
    class="button"
    :color-preset="MainPageButtonPreset.TAB"
    :size-preset="changeXL(SizePresets.M, SizePresets.S)"
    :type-preset="TypePresets.ROUND"
    :text="text"
    @click="$emit('click')"
  >
    <template #prepend>
      <SvgoInfoIcon />
    </template>
  </SharedKitButton>
</template>

<script setup lang="ts">
import { MainPageButtonPreset } from '~/features/mainPage/presets/SharedKitButton.presets';
import { SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import type {
  ISharedHelpInfoButtonEmits,
  ISharedHelpInfoButtonProps,
} from '~/components/SharedHelpInfoButton/SharedHelpInfoButton.types';

defineEmits<ISharedHelpInfoButtonEmits>();
withDefaults(defineProps<ISharedHelpInfoButtonProps>(), {
  text: 'Как работает ивент?',
});

const changeXL = GlobalUtils.Media.changeByMedia('xl');
</script>

<style scoped lang="scss" src="./SharedHelpInfoButton.scss" />
